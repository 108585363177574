import React from "react"
import { Container, Row, Col } from "reactstrap"
import { FaCode } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { Paragraph } from "grommet"

let StyledImg = styled((props) => <Img {...props} />)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  img {
    transform: rotateY(-30deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1),
      16px 40px 75px -40px rgba(0, 0, 0, 0.2);
    border-radius: 0.625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-25deg) rotateX(15deg);
    }
  }
`

let Benefit = ({ title, content }) => (
  <div className="d-flex mb-4">
    <FaCode size={30} className="text-primary" />
    <div className="ml-3">
      <h4>{title}</h4>
      <Paragraph>{content}</Paragraph>
    </div>
  </div>
)

let Benefits = ({ coverPhoto }) => {
  const data = useStaticQuery(graphql`
    fragment defaultImage on File {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query fallbackImageQuery {
      slide1: file(relativePath: { eq: "sample.png" }) {
        ...defaultImage
      }
    }
  `)
  return (
    <Container className="py-5">
      <Row className="d-flex align-items-center">
        <Col md="6">
          <div className="mb-4">
            <h2 className="text-primary">
              Professional BIM & CAD Training
            </h2>
            <Paragraph>
              Over 20 years of experience in BIM Training &
              certification{" "}
            </Paragraph>
          </div>
          <Benefit
            title="Customised Training Plans"
            content="Our modular courses are tailored to your project requirements"
          />
          <Benefit
            title="Experienced Trainers"
            content="Over 20 years of exprience in BIM Training and Certifcation"
          />
          <Benefit
            title="Up-to-date Curriculum"
            content="Our training standards allow us to qualify as an Autodesk Authorized Training Center and Bentley Training Partner"
          />
        </Col>
        <Col md="6">
          <StyledImg
            fluid={
              coverPhoto ||
              data.slide1.childImageSharp.fluid
            }
            objectFit="contain"
            objectPosition="50% 50%"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Benefits
