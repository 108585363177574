import React, { useState } from "react"
import { NormalField } from "reactstrap-form-fields"
import { Label, Select, Input } from "reactstrap"
import { Link } from "gatsby"
import styled from "styled-components"
import DatePicker, { addDays } from "react-datepicker"
import { loadStripe } from "@stripe/stripe-js"
import { API } from "aws-amplify"

import { createCustomer } from "../../graphql/mutations"
import Button from "../buttons/btn"
import { useInput } from "../../utils/hooks/input-hook"
import "react-datepicker/dist/react-datepicker.css"

let DateContainer = styled.div`
  display: flex;
  flex-direction: column;

  .date-label {
    margin: 0.4em 0;
  }
`
let StyledButton = styled(Button)`
  display: block;
  margin: 1em 0 0.4em 0;
  :disabled {
    background-color: #6c757d;
  }
`

let StyledLabel = styled(Label)`
  display: block;
  color: #007bff;
  font-size: 1rem;
  text-align: center;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const Form = ({
  course,
  redirectToCheckout,
  setRegister,
  setModal,
}) => {
  const {
    value: fname,
    bind: bindFname,
    reset: resetFname,
  } = useInput("")
  const {
    value: email,
    bind: bindEmail,
    reset: resetEmail,
  } = useInput("")
  const {
    value: phone,
    bind: bindPhone,
    reset: resetPhone,
  } = useInput("")
  const [startDate, setStartDate] = useState(minDate)
  const [startTime, setStartTime] = useState("")
  const [quote, setQuote] = useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const apiName = "stripeAPI"
  const apiEndpoint = "/checkout"
  const body = {
    quantity: 1,
    priceId:
      "test" === process.env.GATSBY_ENV ||
      "dev" === process.env.GATSBY_ENV
        ? `${course.product.apiIdTest}`
        : `${course.product.apiID}`,
    client_reference_id: "UniqueString",
    date: `${startDate}`,
  }

  const dateToday = new Date()
  const minDate = dateToday.setDate(
    dateToday.getDate() + 14
  )
  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  // const onPayment = async (event) => {
  //   event.preventDefault();

  //   submitCustomer();

  //   const session = fetchSession();
  //   const sessionId = session.id;
  //   const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
  //   const stripe = await stripePromise;

  //   stripe.redirectToCheckout({sessionId: sessionId})
  // };

  async function submitCustomer(e) {
    e.preventDefault()
    try {
      const result = await API.graphql({
        query: createCustomer,
        variables: {
          input: {
            name: fname,
            email: email,
            phone: phone,
            start_date: startDate,
            start_time: startTime,
          },
        },
      })
      if (!result.hasOwnProperty("errors")) {
        setSubmitted(true)
      }
    } catch (error) {
      console.log(error)
    }

    setModal(true)
  }

  async function fetchSession() {
    try {
      const session = await API.post(apiName, apiEndpoint, {
        body,
      })
      return session
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <form name="Contact Form" onSubmit={submitCustomer}>
        <h5>{course ? course.title : null}</h5>
        <NormalField
          name="Full Name"
          {...bindFname}
          required
        />
        <NormalField name="Email" {...bindEmail} required />
        <NormalField
          name="Phone Number"
          {...bindPhone}
          required
        />
        <DateContainer>
          {/* <Label className="date-label">Pick Exam Date: {'   '} </Label>
        <DatePicker 
          selected={startDate} 
          onChange={(date) => setStartDate(date)} 
          minDate={minDate}
          filterDate={isWeekday} 
          required
        />
        <Label className="date-label">Pick Exam Time: {'   '} </Label>
        <Input 
          type="select" 
          onChange={(e) => setStartTime(e.target.value)}
          required>
            <option>10 am </option>
            <option>2 pm </option>
            <option>4 pm </option>
        </Input> */}
          <StyledButton type="submit" disabled={submitted}>
            Register
          </StyledButton>
        </DateContainer>
      </form>
      <StyledLabel onClick={() => setRegister(false)}>
        Go Back
      </StyledLabel>
    </>
  )
}

export default Form
