import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Button from "../components/buttons/btn"
import SEO from "../components/seo"
import {
  FaCheck,
  FaClock,
  FaDollarSign,
  FaPen,
  FaQuestionCircle,
} from "react-icons/fa"
import Slider from "../components/slider-courses"
import Benefits from "../components/benefits"
import Testimonials from "../components/testimonials"
import Form from "../components/forms/form-certification"
import Partners from "../components/partners"
import TitleBanner from "../components/titleBanner/TitleBanner"
import BasicInfo from "../components/product-detail/basicInfo/BasicInfo"
import CourseDescription from "../components/product-detail/itemDescription/ItemDescription"
import CourseReviews from "../components/product-detail/itemReviews/Reviews"
import MoreCourses from "../components/product-detail/moreItems/MoreItems"
import Reviews from "../components/product-detail/itemReviews/Reviews"
import ItemDescription from "../components/product-detail/itemDescription/ItemDescription"
import AcademyAdvantagesInfo from "../components/product-detail/academyAdvantagesInfo/AcademyAdvantagesInfo"
import MoreItems from "../components/product-detail/moreItems/MoreItems"

export const query = graphql`
  query singleCertificationQuery($slug: String!) {
    contentfulCertifications(slug: { eq: $slug }) {
      id
      slug
      certificationTitle
      certificationDescription
      certificationObjectives
      expiry
      skillLevel
      duration
      totalQuestions
      coverPhoto {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      product {
        name
        price
        currency
        apiID
        apiIdTest
      }
    }
  }
`

const CoursePost = ({ data }) => {
  const [date, setDate] = useState(new Date())
  const [register, setRegister] = useState(false)
  const {
    coverPhoto,
    certificationTitle,
    certificationDescription,
    certificationObjectives,
    totalQuestions,
    duration,
    skillLevel,
    expiry,
    product,
    slug,
  } = data.contentfulCertifications
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const renderCourseDetails = () => {
    return (
      <>
        <CardTitle tag="h4">Exam Details</CardTitle>
        <CardSubtitle tag="h6" className="mb-3 text-muted">
          <FaClock /> Duration: {duration || null}
        </CardSubtitle>
        <CardSubtitle tag="h6" className="mb-3 text-muted">
          <FaQuestionCircle /> Questions:{" "}
          {totalQuestions || null}
        </CardSubtitle>
        <CardSubtitle tag="h6" className="mb-3 text-muted">
          <FaDollarSign /> Price: ${product?.price}
        </CardSubtitle>
        <CardSubtitle tag="h6" className="mb-3 text-muted">
          <FaPen /> Skill Level: {skillLevel || null}
        </CardSubtitle>
        <StyledButton
          margin={"1rem"}
          onClick={() => setRegister(true)}
        >
          Register for Exam
        </StyledButton>
      </>
    )
  }

  return (
    <>
      <SEO
        title={certificationTitle}
        description={certificationDescription}
      />

      <TitleBanner
        title={certificationTitle}
        description="We conduct trainings with BIM Courses for AutoCad, Revit, ProjectWise360, BIM360 and many more"
        withoutFilter
        isCertificate
        slug={slug}
      />

      <div className="container">
        <BasicInfo isCertificate />
        <ItemDescription isCertificate />

        <Reviews isCertificate />

        <MoreItems isCertificate />

        <AcademyAdvantagesInfo />
      </div>

      {/* <Slider
        title={certificationTitle}
        description="Ace Industrial Academy is an Autodesk Authorized Training Center and a Pearson Vue Testing Center"
      />
      <Container className="py-4">
        <Row>
          <Col xs={12} md={8}>
            <h3>Overview</h3>
            <p>{certificationDescription || null}</p>
            <h4>Certification Objectives</h4>
            {certificationObjectives?.map(
              (objective, i) => (
                <p key={i}>
                  <FaCheck /> {objective}
                </p>
              )
            )}
          </Col>
          <Col sm={12} md={4}>
            <StyledCard>
              <CardBody>
                {!register ? (
                  renderCourseDetails()
                ) : (
                  <Form
                    course={data.contentfulCertifications}
                    setRegister={setRegister}
                    setModal={setModal}
                  />
                )}
              </CardBody>
            </StyledCard>
          </Col>
        </Row>
      </Container>
      <StyledBackground>
        <Benefits coverPhoto={coverPhoto?.fluid} />
      </StyledBackground>

      <Partners />
      <Testimonials /> */}
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody className="text-center">
            <h4>Submission received</h4>
            <p>
              We will get in touch with you within 1 working
              day.
            </p>
          </ModalBody>
          <ModalFooter className="justify-content-around">
            <Button
              color="secondary"
              onClick={() => {
                toggle()
              }}
              className="w-25"
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

export default CoursePost

let StyledBackground = styled.div`
  background: linear-gradient(
    to bottom,
    #f9fbfd 0,
    #fff 100%
  );
`

let StyledCard = styled(Card)`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 1rem;

  @media (max-width: 640px) {
    margin-top: 2rem;
  }
`

let StyledButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 2rem;
`
